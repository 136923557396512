import React from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import { Title } from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Browser Version",
    link: "/what-is-my-browser-version/",
  },
]
const seeAlsoArray = [
  "/ip-address",
  "/where-am-i-location-latitude-longitude",
  "/find-location-of-ip-address",
  "/screen-resolution",
]

function MyBrowserVERSION() {
  let myBrowser, myVersion

  function get_browser() {
    if (typeof window !== "undefined") {
      let N, ua, tem

      N = navigator.appName
      ua = navigator.userAgent

      let M = ua.match(
        /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i
      )
      if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1]
      M = M ? [M[1], M[2]] : [N, navigator.appVersion, "-?"]
      myBrowser = M[0]
      return myBrowser
    }
  }
  get_browser()

  function get_browser_version() {
    if (typeof window !== "undefined") {
      let N, ua, tem

      N = navigator.appName
      ua = navigator.userAgent

      let M = ua.match(
        /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i
      )
      if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1]
      M = M ? [M[1], M[2]] : [N, navigator.appVersion, "-?"]
      myVersion = M[1]
      return myVersion
    }
  }
  get_browser_version()
  return (
    <div>
      <p className="h5">
        Your browser is:
        <strong> {myBrowser} </strong>
      </p>
      <p className="h5">
        Version:<strong> {myVersion}</strong>
      </p>
    </div>
  )
}

function MyBrowserVersion(props) {
  let width, height, colorDepth, fullnameBrowserAndVersion
  if (typeof window !== "undefined") {
    width = window.screen.width
    height = window.screen.height
    colorDepth = window.screen.colorDepth
    fullnameBrowserAndVersion = navigator.userAgent
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="My Browser Version - what is my Browser Version?"
        description="Browser Version, What is My Browser Version? Simple Online Tool To Find Your Internet Browser Version Online."
        keywords={[
          "Browser Version,what Browser Version i have,what is my Browser Version,what Browser Version have,Browser Version i have,my Browser Version,find my Browser Version,Find Your Internet Browser Version, what is my version of browser,version of my browser,my version chrome.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>What Is My Browser Version ?</Title>

        <section className="container">
          <MyBrowserVERSION />
          <br />
          <p className="mycolor">
            Your full user agent string is:{" "}
            <span>{fullnameBrowserAndVersion}</span>
          </p>
          <br />
          <FlexDiv maxWidth="300px">
            <SmallImg
              filename="browsing.png"
              alt="what is my Browser Version"
            />
          </FlexDiv>
        </section>
        <br />
        <h3>What is my browser?</h3>
        <p>
          A browser is a software application that has a numeral version to know
          what version you have if it is the last version, that lets you opening
          web pages on the Internet.
          <br />
          Common browsers: Google Chrome, Firefox, Safari, and Internet
          Explorer.
          <br />
          The Google Chrome browser is the most popular and used browser in the
          world.
        </p>
        <br />
        <br />
        <p className="mycolor">
          Your Screen Resolution:
          <strong>
            {" "}
            {width} &times; {height}
          </strong>
          , Your Screen Width: <strong> {width} pixels </strong>, Your Screen
          Height: <strong> {height} pixels </strong>, Your Color Depth:{" "}
          <strong>{colorDepth} bits per pixel</strong>
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default MyBrowserVersion
